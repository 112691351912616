<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>诊单管理</el-breadcrumb-item>
      <el-breadcrumb-item>寻医列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-select
        v-model="searchstate"
        placeholder="请选择状态"
        style="width: 140px; margin-right: 10px"
        @change="getall(1, searchstate)"
        size="small"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.value"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <!-- 寻医编号： -->
      <el-input
        placeholder="请输入寻医表编号"
        clearable
        prefix-icon="el-icon-search"
        v-model="searchfiel"
        style="width: 200px"
        @clear="
          getall(1, '1');
          searchstate = 1;
          showpage = true;
        "
        size="small"
      >
      </el-input>
      <el-button
        type="primary"
        style="margin-left: 10px"
        @click="query"
        size="small"
        >查询</el-button
      >
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="KID" label="寻医单编号"></el-table-column>
      <el-table-column prop="Dis_Name" label="寻医单名"></el-table-column>
      <el-table-column prop="Cust_ID" label="患者编号"></el-table-column>
      <el-table-column prop="State" label="状态">
        <template slot-scope="scope">
          <span>{{ transformstate(scope.row.State) }}</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div class="opt">
            <p>
              <!-- <a @click="lookup(scope.row)">查看</a> -->
              <el-button
                size="mini"
                type="warning"
                icon="el-icon-view"
                @click="lookup(scope.row)"
                >查看</el-button
              >
              <el-button
                size="mini"
                type="success"
                icon="el-icon-edit"
                @click="
                  dialogFormVisible = true;
                  KID = scope.row.KID;
                  addrow = scope.row;
                "
                >修改状态</el-button
              >
            </p>
            <p>
              <el-button
                v-if="scope.row.State == 6"
                size="mini"
                type="primary"
                icon="el-icon-user-solid"
                @click="assigDoc(scope.row)"
                >指定医生</el-button
              >
              <!-- <a @click="dialogFormVisible = true;KID=scope.row.KID">修改状态</a> -->
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging" v-if="showpage">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        :hide-on-single-page="value"
      ></el-pagination>
    </p>
    <el-dialog
      title="寻医表"
      :modal="false"
      :visible.sync="EditXY"
      width="566px"
    >
      <xypreview
        :kid="EditXYMsg.kid"
        :custid="EditXYMsg.custid"
        v-if="EditXY"
        :EditZD="EditXY"
        :noedit="EditXYMsg.noedit"
        :isshenhe="isshenhe"
      ></xypreview>
    </el-dialog>
    <el-dialog title="修改状态" :visible.sync="dialogFormVisible">
      <p>
        患者 {{ addrow.Cust_ID }} 的 {{ addrow.Dis_Name }} 寻医表
        {{ addrow.KID }}
      </p>
      <el-select
        v-model="changestate"
        placeholder="请选择要修改的状态"
        size="small"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.value"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogFormVisible = false;
            changestate = '';
          "
          size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="ModifyState" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <talkassigDoc
      :assigdoctor.sync="assigdoctor"
      :assigdoctoritem="assigdoctoritem"
    ></talkassigDoc>
  </div>
</template>
<script>
import xypreview from "../components/xypreview.vue";
import talkassigDoc from "../components/talkassigDoc2.vue";
export default {
  inject: ["reload"],
  data() {
    return {
      searchcustid: "",
      searchfiel: "",
      list: [],
      EditXY: false,
      EditXYMsg: {
        kid: "",
        custid: "",
        noedit: "",
      },
      dialogFormVisible: false,
      changestate: "",
      options: [
        { id: 0, value: "未提交" },
        { id: 1, value: "待挂号" },
        { id: 6, value: "审核" },
        { id: 2, value: "已抓取" },
        { id: 9, value: "删除" },
      ],
      uid: sessionStorage.getItem("id"),
      servicerid: sessionStorage.getItem("servicer_id"),
      KID: "",
      searchstate: 1,
      showpage: true,
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      assigdoctor: false,
      assigdoctoritem: "",
      addrow: "",
      isshenhe: false,
    };
  },
  components: {
    xypreview,
    talkassigDoc,
  },
  mounted() {
    this.getall(1, "1");
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      if (this.searchfiel == "" && this.searchcustid == "") {
        this.getall(currentPage, this.searchstate);
      }
    },
    getall(page, state) {
      this.axios
        .get(
          "/field/get_z_newseadoc_mains?state=" +
            state +
            "&page=" +
            page +
            "&size=" +
            this.PageSize
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
        });
    },
    transformstate(val) {
      var str = "";
      var dic = {
        0: "未填写",
        1: "待挂号",
        2: "已抓取",
        // 3: "打回",
        6: "审核",
        9: "删除",
      };
      for (var key in dic) {
        if (key == val) {
          str = dic[key];
        }
      }
      return str;
    },
    query() {
      this.list = [];
      this.showpage = false;
      this.searchstate = "";
      if (this.searchfiel == "" && this.searchcustid == "") {
        this.$message({
          message: "请输入搜索条件",
          type: "error",
        });
        return;
      }
      if (
        (this.searchfiel !== "" ||
          this.searchfiel !== null ||
          this.searchfiel !== undefined) &&
        this.searchcustid == ""
      ) {
        this.axios
          .get("/field/get_z_newseadoc_main_by_kid?kid=" + this.searchfiel)
          .then((res) => {
            if (res.data.code == 0) {
              this.list.push(res.data.result);
            } else {
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    lookup(row) {
      // sessionStorage.setItem('Cust_ID', row.Cust_ID);
      // // sessionStorage.setItem('MedicalType', row.State);
      // this.$router.push({ path: "/XYOrderDetail", query: { id: row.KID } })
      this.EditXY = true;
      this.EditXYMsg.kid = row.KID;
      this.EditXYMsg.custid = row.Cust_ID;
      this.EditXYMsg.noedit = false;
      // if (row.State == 1) {
      //   this.isshenhe = true;
      // } else {
      //   this.isshenhe = false;
      // }
    },
    ModifyState() {
      // if (this.changestate == 3) {
      //     this.changestate = 0;
      // }
      if (
        this.changestate === "" ||
        this.changestate == null ||
        this.changestate == undefined
      ) {
        this.$message({
          message: "请选择状态",
          type: "error",
        });
        return;
      }
      if (this.KID === "" || this.KID == null || this.KID == undefined) {
        this.$message({
          message: "没有编号ID",
          type: "error",
        });
        return;
      }
      if (
        this.servicerid === "" ||
        this.servicerid == null ||
        this.servicerid == undefined
      ) {
        this.$message({
          message: "没有服务人编号",
          type: "error",
        });
        return;
      }
      //       var addill = {};
      if (this.changestate == 6) {
        this.EditXY = true;
        this.EditXYMsg.kid = this.addrow.KID;
        this.EditXYMsg.custid = this.addrow.Cust_ID;
        this.EditXYMsg.noedit = false;
        this.isshenhe = true;
      } else {
        this.axios
          .post(
            "/field/update_z_newseadoc_main_state",
            this.qs.stringify({
              state: this.changestate,
              kid: this.KID,
              uid: this.servicerid,
            })
          )
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.dialogFormVisible = false;
              this.query();
              // this.State = this.changestate;
              // sessionStorage.setItem("MedicalType", this.changestate);
              // this.reload();
            } else {
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    assigDoc(row) {
      // this.axios.get("/gu/get_cust_info_by_custid?cust_id=" + row.Cust_ID).then((res) => {
      //   if (res.data.code == 0) {
      //     var docid = res.data.Cust_Info.Doc_ID;
      //     if (docid == "" || docid == null || docid == undefined || docid == "null") {
      this.assigdoctor = true;
      this.assigdoctoritem = row;
      //     } else {
      //       this.$alert(
      //         "此患者已有绑定医生。如需更换医生请先与患者沟通，与原医生解绑后才可绑定新的医生。",
      //         "提示",
      //         { confirmButtonText: "确定" }
      //       );
      //     }
      //   }
      // });
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
}
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}
</style>
